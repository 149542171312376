import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss'
import '@/assets/css/main.css'
import request from "@/utils/request";
import '@/assets/iconfont/iconfont.css'
/**重置message，防止重复点击重复弹出message消息提示 */
import messageToast from "@/utils/Message";

Vue.config.productionTip = false

Vue.prototype.$request = request
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL

Vue.prototype.$fileUrl = process.env.VUE_APP_BASEURL + '/file/upload'

Vue.use(ElementUI, {size: "small"});

Vue.prototype.$message = messageToast

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')





