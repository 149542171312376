import {Message} from 'element-ui'

let messageUse = null
const messageToast = (options) => {
    if (messageUse) {
        messageUse.close()
    }
    messageUse = Message(options)
}

// 为messageToast添加四个提示框类型，判断传入的数据是否为字符串，是的话则可以判断为一段文本，我们直接修改options对象的message属性
['success', 'error', 'info', 'warning'].forEach(item => {
    messageToast[item] = options => {
        if (!options) {
            options = { message: '' }
        }
        if (typeof options === 'string') {
            options = {
                message: options
            }
        }
        options.type = item
        return messageToast(options)
    }
})

export default messageToast
