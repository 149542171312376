import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

let originPush =  VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => {}, () => {});
  }
}

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/views/Layout'),
    redirect: '/home',
    children: [
      { path: 'home', component: () => import('@/views/Home') },
      { path: 'project', component: () => import('@/views/Project') },
      { path: 'modelConfigDetail', component: () => import('@/views/project/ModelConfigDetail') },
      { path: 'generate', component: () => import('@/views/Generate') },
      { path: 'download', component: () => import('@/views/Download') },
      { path: 'recharge', component: () => import('@/views/recharge/Recharge') },
      { path: 'rechargeDetail', component: () => import('@/views/recharge/RechargeDetail') },
      { path: 'exchange', component: () => import('@/views/recharge/Exchange') },
      { path: 'addFeedback', component: () => import('@/views/feedback/AddFeedback') },
      { path: 'feedback', component: () => import('@/views/feedback/Feedback') },
      { path: 'snapshot', component: () => import('@/views/snapshot/Snapshot') },
      { path: 'snapshotModelConfigDetail', component: () => import('@/views/snapshot/ModelConfigDetail') },
      { path: 'scoreExchange', component: () => import('@/views/recharge/ScoreExchange') },
    ]
  },
  { path: '/apply', component: () => import('@/views/Apply') },
  { path: '/auth', component: () => import('@/views/Auth') },
  { path: '/jump', component: () => import('@/views/Jump') },
  { path: '/forbidden', component: () => import('@/views/Forbidden') },
  { path: '/login', component: () => import('@/views/Login')},
  { path: '/payDoc', component: () => import('@/views/doc/PayDoc') },
  { path: '/agreement', component: () => import('@/views/doc/Agreement') },
  { path: '/secretDoc', component: () => import('@/views/doc/SecretDoc') },
  { path: '*', component: () => import('@/views/404') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
